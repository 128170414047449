import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../../layouts/MainPagetitle";
import moment from "moment";

const ManualDisbursementView = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  let editmode = props.urlparams.params.readonly !== "true" ? true : false;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;

  const [loanfields, setLoanfields] = useState([
    {
      name: "borrowerName",
      label: "Customer Name",
      type: "text",
    },
    {
      name: "loanAccountNumber",
      label: "Loan Account No",
      type: "text",
    },
    {
      name: "sanctionedLoanAmount",
      label: "Sanctioned Loan amount",
      type: "text",
    },
    {
      name: "sanctionedLoanTenure",
      label: "Tenure",
      type: "text",
    },
    {
      name: "sanctionedLoanPF",
      label: "Processing Fees",
      type: "text",
    },
    {
      name: "loanPaymentMode",
      label: "Repayment Type",
      type: "select",
      options: _enum.REPAYMENT_TYPE_KEY,
    },
    {
      name: "sanctionedLoanStartDate",
      label: "Loan Start date",
      type: "date",
      dateClassName: "fakedisabled",
      // onChange: (date) => handleStartDateChange(date),
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
    },
    {
      name: "sanctionedLoanEndDate",
      label: "Loan End date",
      type: "date",
    },
    {
      name: "sanctionedLoanTotalAmtPayable",
      label: "Total Amt payable",
      type: "text",
    },
    {
      name: "loanDetailsRemarks",
      label: "Remarks",
      type: "textarea",
    },
  ]);
  const [bankfields, setBankfields] = useState([
    {
      name: "accountHolderName",
      label: "Account Holder Name",
      type: "text",
    },
    {
      name: "accountNumber",
      label: "Account No",
      type: "text",
    },
    {
      name: "accountHolderBankName",
      label: "Bank Name",
      type: "text",
    },
    {
      name: "accountHolderBankIFSC",
      label: "IFSC Code",
      type: "text",
    },
    {
      name: "bankDetailsRemarks",
      label: "Remarks",
      type: "textarea",
    },
  ]);
  const [disbursementFields, setDisbursementFields] = useState([
    {
      name: "disbursementDate",
      label: "Disbursement Date",
      type: "date",
      required: true,
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "textarea",
      required: true,
    },
  ]);
  const [editdata, setEditdata] = useState({});

  useEffect(() => {
    getPredisbursedApplication();
    console.log({ editmode });
  }, []);

  const getPredisbursedApplication = () => {
    let payload = {
      loanAccountNumber: props.urlparams.params.lanid,
    };
    props.apis
      .apiGetUnderWriterPredisbursedApplicationByNumber(payload)
      .then((res) => {
        if (res) {
          setEditdata(res);
          let updatedFieldArray = updateDefaults(loanfields, res);
          setLoanfields(updatedFieldArray);

          let updatedBankFieldArray = updateDefaults(bankfields, res);
          setBankfields(updatedBankFieldArray);

          let updatedDisbursementFieldArray = updateDefaults(
            disbursementFields,
            Object.except(res, ["remarks"])
          );
          setDisbursementFields(updatedDisbursementFieldArray);
        }
      });
  };

  const updateDefaults = (arr, applicationData) => {
    let defaultArr = arr.map((o) => {
      let defaultValue = o.defaultValue || applicationData[o.name] || "";

      if (o.name === "sanctionedLoanTotalAmtPayable") {
        let interestRate = applicationData.sanctionedLoanIRR;
        let loanAmount = applicationData.sanctionedLoanAmount;
        let loanduration = applicationData.sanctionedLoanTenure;
        // console.log({
        //   interestRate,
        //   loanAmount,
        //   loanduration,
        // });
        let interestAmount = loanAmount * 0.01 * loanduration;
        let totalAmount = (loanAmount + interestAmount).toFixed(2);
        // console.log({ totalAmount });
        return {
          ...o,
          defaultValue: totalAmount,
          readOnly: true,
        };
      }

      if (editmode) {
        if (
          [
            "sanctionedLoanStartDate",
            "disbursementRemarks",
            "disbursementDate",
            "remarks",
          ].includes(o.name)
        ) {
          return {
            ...o,
            defaultValue,
          };
        } else {
          return {
            ...o,
            defaultValue,
            readOnly: true,
          };
        }
      } else {
        return {
          ...o,
          defaultValue,
          readOnly: true,
        };
      }
    });
    console.log({ defaultArr });
    return defaultArr;
  };

  const handleManualDisbursement = (e, data) => {
    console.log({ data });

    let payload = {
      disbursementDate: data?.disbursementDate
        ? new Date(data?.disbursementDate).toISOString()
        : null,
      remarks: data?.remarks,
    };
    console.log({ payload });
    if (payload.disbursementDate === null) {
      swal2.fire({
        title: "Disbursement Date is Required",
        icon: "error",
        iconColor: "#DE3232",
      });
    } else {
      swal2
        .fire({
          title:
            "Are you sure want to manually disburse this loan application ?",
          icon: "info",
          iconColor: "#DE3232",
          showCancelButton: true,
          confirmButtonColor: "#DE3232",
          cancelButtonColor: "##D6DBE7",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) {
            props.apis
              .apiAdminAddManualDisbursement(payload, {
                loanAccountNumber: props.urlparams.params.lanid,
              })
              .then((res) => {
                console.log({ res });
                swal2.fire({
                  title: "Loan Disbursed Successfully",
                  icon: "success",
                  iconColor: "#DE3232",
                  timer: 3000,
                  confirmButtonText: "Close",
                });
                navigate("/app/loandetails");
              })
              .catch((err) => {
                navigate("/app/loandetails");
              });
          }
        });
    }
  };

  const getMainTitle = () => {
    let mainTitle =
      "Loan Details | Pending for Disbursement | Manual Disbursement";
    return mainTitle;
  };

  const getBreadCums = () => {
    let pagetitle = props?.urlparams?.params?.name || "";
    return pagetitle;
  };

  return (
    <>
      <MainPagetitle
        mainTitle={getMainTitle()}
        back={getBreadCums()}
        backLink="/app/loandetails"
        pageTitle={"Loan Details"}
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckImg}
      />
      {/* <div className="custom-tab-1 p-4"></div> */}

      <div className="container-fluid">
        <AppForm className="mx-0" onSubmit={handleManualDisbursement}>
          <div className="card p-4">
            <h2 className="form_title">Loan Deatils</h2>
            <div className="row">
              {loanfields?.map((field, i) => (
                <div className="col-12 col-md-4">
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>
          </div>
          <div className="card p-4">
            <h2 className="form_title">Bank A/C Details</h2>
            <div className="row">
              {bankfields?.map((field, i) => (
                <div className="col-12 col-md-4">
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>
          </div>
          <div className="card p-4">
            <h2 className="form_title">Disbursement Details</h2>
            <div className="row">
              {disbursementFields?.map((field, i) => (
                <div className="col-12 col-md-4">
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="card profile_card tvr_comment mx-0">
            <div className="card-header p-0 border-0">
              <div className="d-flex align-items-center mb-3">
                <h4 className="theme_text_sm ms-2 mb-0">TVR Comment</h4>
              </div>
            </div>
            <div className="card-body p-0 ms-2">
              <p className="comment_heading">Comment</p>
              <p className="comment">
                {editdata?.application?.customerTVRComment || "-"}
              </p>
            </div>
          </div> */}

          <div className="nav_container">
            <Link
              to={"/app/loandetails"}
              className={Object.className({
                "btn btn-primary-lite me-3": true,
                "d-none": !isAdmin || isReadonly,
              })}
            >
              Close
            </Link>
            <button
              type="submit"
              className={Object.className({
                "btn btn-primary": true,
                "d-none": !isAdmin || !editmode,
              })}
            >
              Save & Submit
            </button>
          </div>
        </AppForm>
      </div>
    </>
  );
};

export default constore(ManualDisbursementView);
