import masterdataApi from "./devapis/masterdata.api";
import leadsApi from "./devapis/leads.api";
import applicationsApi from "./devapis/applications.api";
import customerApi from "./devapis/customer.api";
import underwriterApi from "./devapis/underwriter.api";

export default {
    ...masterdataApi,
    ...leadsApi,
    ...applicationsApi,
    ...customerApi,
    ...underwriterApi,

    HEARTBEAT: {
        url: '/api/v1/heartbeat',
        method: "get",
    },
    LOGIN: {
        url: "/api/v1/auth/login",
        method: "post"
    },
    OTP_LOGIN: {
        url: "/api/v1/auth/otp/{mobile}",
        method: "get"
    },
    OTP_LOGIN_VERIFY: {
        url: "/api/v1/auth/verify",
        method: "post"
    },
    REFRESH_TOKEN: {
        url: "/api/v1/auth/refresh",
        method: "post"
    },
    PASSWORD_RESET: {
        url: "/api/v1/auth/change",
        method: "put"
    },
    GENERATE_TEMPORARY_PASSWORD: {
        url: "/api/v1/auth/reset/{email}",
        method: "put"
    },
    GET_USER: {
        url: "/api/v1/auth/me",
        method: "get"
    },
    LOGOUT: {
        url: "/api/v1/auth/logout",
        method: "post"
    },

    // FILE API'S
    FILE_UPLOAD: {
        url: "/api/v1/files/upload/{foldername}",
        method: "post"
    },
    GET_FILE_METADATA: {
        url: "/api/v1/files/metadata/{fileId}",
        method: "get"
    },
    FILE_DOWNLOAD: {
        url: "/api/v1/files/download/{fileId}",
        method: "get"
    },
    THUMBNAIL_DOWNLOAD: {
        url: "/api/v1/files/tuhmbnail/{fileId}",
        method: "get"
    },
    DELETE_FILE: {
        url: "/api/v1/files/remove/{fileId}",
        method: "delete"
    },

    // MY PROFILE
    GET_EMPLOYEE: {
        url: "/api/v1/profile",
        method: "get"
    },
    CREATE_EMPLOYEE: {
        url: "/api/v1/profile",
        method: "post",
    },
    UPDATE_EMPLOYEE: {
        url: "/api/v1/profile/{profileId}",
        method: "put"
    },
    GET_EMPLOYEE_BY_ID: {
        url: "/api/v1/profile/{profileId}",
        method: "get"
    },
    DELETE_EMPLOYEE: {
        url: "/api/v1/profile/{profileId}",
        method: "delete"
    },
    GET_MY_INFO: {
        url: "/api/v1/profile/myinfo",
        method: "get",
    },
    GET_SINGLE_PROFILE_INFO: {
        url: "/api/v1/profile/{profileId}",
        method: "get",
    },
    // ADMIN DASHBOARD APIS

    // REVENUE INFO
    GET_REVENUE_TOTAL_COLLECTIONS: {
        url: "/api/v1/dashboard/admin/revenue/overall/{timeline}",
        method: "get",
    },
    GET_REVENUE_PROCESSING_FEES: {
        url: "/api/v1/dashboard/admin/revenue/pf/{timeline}",
        method: "get",
    },
    GET_REVENUE_PRINCIPLE: {
        url: "/api/v1/dashboard/admin/revenue/principle/{timeline}",
        method: "get",
    },
    GET_REVENUE_INTEREST: {
        url: "/api/v1/dashboard/admin/revenue/interest/{timeline}",
        method: "get",
    },

    // ACTIVITIES
    GET_DASHBOARD_ADMIN_ACTIVITIES_OVERDUE_YESTERDAY: {
        url: "/api/v1/dashboard/admin/activities/overdue/yesterday",
        method: "get",
    },
    GET_DASHBOARD_ADMIN_COLLECTIONS_OVERDUE_YESTERDAY: {
        url: "/api/v1/dashboard/admin/activities/collections/yesterday",
        method: "get",
    },
    GET_DASHBOARD_ADMIN_ACTIVITIES_OVERDUE_TODAY: {
        url: "/api/v1/dashboard/admin/activities/overdue/today",
        method: "get",
    },
    GET_DASHBOARD_ADMIN_COLLECTIONS_OVERDUE_TODAY: {
        url: "/api/v1/dashboard/admin/activities/collections/today",
        method: "get",
    },

    // OVERDUE PORTFOLIO 
    GET_DASHBOARD_ADMIN_OVERDUE_PORTFOLIO: {
        url: "/api/v1/dashboard/admin/overdue",
        method: "get",
    },

    // LEADS INFO 
    GET_DASHBOARD_ADMIN_LEADS_INFO: {
        url: "/api/v1/dashboard/admin/leads",
        method: "get",
    },

    // OVERALL PORTFOLIO
    GET_OVERALL_DISBURSEMENT_DETAILS: {
        url: "/api/v1/dashboard/admin/overall/disbursement/{timeline}",
        method: "get",
    },
    GET_OVERALL_ACTIVE_DETAILS: {
        url: "/api/v1/dashboard/admin/overall/active/{timeline}",
        method: "get",
    },
    GET_OVERALL_CLOSED_DETAILS: {
        url: "/api/v1/dashboard/admin/overall/closed/{timeline}",
        method: "get",
    },
    GET_OVERALL_OVERDUE_DETAILS: {
        url: "/api/v1/dashboard/admin/overall/overdue/{timeline}",
        method: "get",
    },

    // DISBURSEMENT DETAILS
    GET_TOTAL_DISBURSEMENT_DETAILS: {
        url: "/api/v1/dashboard/admin/disbursement/total/{timeline}",
        method: "get",
    },
    GET_DISBURSEMENT_DETAILS_BY_CHANNEL: {
        url: "/api/v1/dashboard/admin/disbursement/channel/{channel}/{timeline}",
        method: "get",
    },

    // USER REGISTRATIONS
    GET_TOTAL_REGISTERED_USERS: {
        url: "/api/v1/dashboard/admin/customer/total",
        method: "get",
    },
    GET_PENDING_REGISTERED_USERS: {
        url: "/api/v1/dashboard/admin/customer/pending",
        method: "get",
    },
    GET_COMPLETED_REGISTERED_USERS: {
        url: "/api/v1/dashboard/admin/customer/completed",
        method: "get",
    },
    GET_APPLIED_REGISTERED_USERS: {
        url: "/api/v1/dashboard/admin/customer/applied",
        method: "get",
    },

    // APPLICATION STATUS
    GET_TOTAL_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/total",
        method: "get",
    },
    GET_PENDING_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/pending",
        method: "get",
    },
    GET_UNDERWRITING_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/underwriting",
        method: "get",
    },
    GET_SANCTIONED_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/sanctioned",
        method: "get",
    },
    GET_APPROVED_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/approved",
        method: "get",
    },
    GET_AGREEMENT_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/agreement",
        method: "get",
    },
    GET_DISBURSED_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/disbursed",
        method: "get",
    },
    GET_REJECTED_APPLICATION_STATUS: {
        url: "/api/v1/dashboard/admin/application/rejected",
        method: "get",
    },
    // DASHBOARD SALES
    GET_DASHBOARD_TELECALLERS_INFO: {
        url: "/api/v1/dashboard/admin/sales/{timeline}",
        method: "get",
    },

    // EXPORT API'S
    EXPORT_APPLICATION_DETAILS: {
        url: "/api/v1/export/application",
        method: "get",
    },
    EXPORT_REGISTERED_USERS: {
        url: "/api/v1/export/customer",
        method: "get",
    },
    EXPORT_EMPLOYEES_DETAILS: {
        url: "/api/v1/export/employees",
        method: "get",
    },
    EXPORT_LEADS_DETAILS: {
        url: "/api/v1/export",
        method: "get",
    },
    EXPORT_LOAN_DETAILS: {
        url: "/api/v1/export/loan",
        method: "get"
    },
    EXPORT_DISBURSEMENT_REPORT: {
        url: "/api/v1/export/report/disbursement",
        method: "get"
    },
    EXPORT_REPAYMENT_REPORT: {
        url: "/api/v1/export/report/repayment",
        method: "get"
    },

    // ADMIN TOOLKIT
    KYC_REQUEST_AADHAR_OTP: {
        url: "/api/v1/toolkit/admin/aadhar/request",
        method: "post"
    },
    KYC_GET_AADHAR_DETAILS_USING_OTP: {
        url: "/api/v1/toolkit/admin/aadhar/verify",
        method: "post"
    },
    KYC_GET_PAN_DETAILS: {
        url: "/api/v1/toolkit/admin/pan",
        method: "post"
    },
    KYC_GET_CIBIL_DETAILS: {
        url: "/api/v1/toolkit/admin/cibil",
        method: "post"
    },
    KYC_GET_CIBIL_USERS_HISTORY: {
        url: "/api/v1/toolkit/admin/cibil/history",
        method: "get"
    },

    // EQUIFAX
    GET_ALL_EQUIFAX_ACCOUNTS: {
        url: "/api/v1/equifax/accounts",
        method: "get"
    },
    GET_TUDF_FILE: {
        url: "/api/v1/equifax/generate/tudf",
        method: "post"
    },
    GET_EQUIFAX_EXCEL_FILE: {
        url: "/api/v1/equifax/generate/excel",
        method: "post"
    },
    // 
    GET_ALL_DISBURSEMENT_APPLICATIONS: {
        url: "/api/v1/admin/application/disburse",
        method: "get"
    },
    GET_ALL_LOAN_REPAYMENTS: {
        url: "/api/v1/loan/repayment/reports",
        method: "get"
    },
}
