import React from "react";

const DocsCard = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/api/v1/files/download/`;
  console.log(props?.data?.panFileMetaData);
  return (
    <div className="card profile_card mx-0">
      <div className="card-header p-0 border-0">
        <div className="d-flex align-items-center mb-3">
          <img
            src={imagepaths.cardGaurdIcon}
            alt="cardGaurdIcon"
            className="card_icon"
          />
          <h5 className="title ms-2 mb-0">Docs Details</h5>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="docs_card_container row g-0">
          <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">PAN Card Front</h2>
              {props?.data?.panFileMetaData &&
              props?.data?.panFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${props?.data?.panFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">Aadhar Card (Front)</h2>
              {props?.data?.aadharFrontFileMetaData &&
              props?.data?.aadharFrontFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${props?.data?.aadharFrontFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">Aadhar Card (Back)</h2>
              {props?.data?.aadharBackFileMetaData &&
              props?.data?.aadharBackFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${props?.data?.aadharBackFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div> */}
          <div className="col-6 col-sm-4 col-md-6 col-lg-3">
            <div className="docs_card">
              <img src={imagepaths.docsCardRedIcon} alt="docsCardRedIcon" />
              <h2 className="title">Photo</h2>
              {props?.data?.photoFileMetaData &&
              props?.data?.photoFileMetaData?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${props?.data?.photoFileMetaData?.fileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default constore(DocsCard);
