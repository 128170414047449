import React, { useEffect, useState } from "react";
import MainPagetitle from "../../layouts/MainPagetitle";
import TelecallerInfoCard from "./elements/TelecallerInfoCard";
import OverallPortfolioCard from "./OverallPortfolioCard";
import RevenueInfo from "./DashboardHelpers/RevenueInfo/RevenueInfo";
import DisbursementDetailsInfo from "./DashboardHelpers/DisbursementDetailsInfo/DisbursementDetailsInfo";
import UserRegistrationInfo from "./DashboardHelpers/UserRegistrationInfo/UserRegistrationInfo";
import LeadInfo from "./DashboardHelpers/LeadInfo/LeadInfo";

const Home = (props) => {
  const [overallPortfolioData, setOverallPortfolioData] = useState({});
  const [applicationStatusData, setApplicationStatusData] = useState({});
  const [activitiesData, setActivitiesData] = useState({});

  const [activeKey, setActiveKey] = useState("portfolio_info");
  const filterTabData = [
    {
      key: "portfolio_info",
      name: "Portfolio Info.",
    },
    {
      key: "sales_info",
      name: "Sales Info.",
    },
    {
      key: "credit_info",
      name: "Credit Info.",
    },
  ];

  const handleSelect = (e, key) => {
    setActiveKey(key);
  };

  const overdueCardData = [
    {
      title: "0-30",
      count: overallPortfolioData?.last0030dayOverdueCount,
      amount: overallPortfolioData?.last0030dayOverdueAmt,
    },
    {
      title: "30-60",
      count: overallPortfolioData?.last6030dayOverdueCount,
      amount: overallPortfolioData?.last6030dayOverdueAmt,
    },
    {
      title: "60-90",
      count: overallPortfolioData?.last6090dayOverdueCount,
      amount: overallPortfolioData?.last6090dayOverdueAmt,
    },
    {
      title: "90-180",
      count: overallPortfolioData?.last90180dayOverdueCount,
      amount: overallPortfolioData?.last90180dayOverdueAmt,
    },
    {
      title: "180+",
      count: overallPortfolioData?.last180day$OverdueCount,
      amount: overallPortfolioData?.last180day$OverdueAmt,
    },
  ];

  const applicationStatusCardData = [
    {
      title: "Total",
      count: applicationStatusData?.totalApplications?.totalApplicationCount,
      amount:
        applicationStatusData?.totalApplications?.totalApplicationLoanAmount,
      icon: imagepaths.dashboardFileIcon,
      path: "/app/loanapplications",
    },
    {
      title: "Pending",
      count: applicationStatusData?.pendingApplications?.applicationCount,
      amount: applicationStatusData?.pendingApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileWarningIcon,
      path: "/app/loanapplications?activetab=pendingapplications",
    },
    {
      title: "Underwriting",
      count: applicationStatusData?.underwritingApplications?.applicationCount,
      amount:
        applicationStatusData?.underwritingApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileSearchIcon,
      path: "/app/loanapplications?activetab=inprogress",
    },
    {
      title: "Sanctioned",
      count: applicationStatusData?.sanctionedApplications?.applicationCount,
      amount:
        applicationStatusData?.sanctionedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileCleanIcon,
      path: "/app/loanapplications?activetab=sanctioned",
    },
    {
      title: "Approved",
      count: applicationStatusData?.approvedApplications?.applicationCount,
      amount:
        applicationStatusData?.approvedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileCheckIcon,
      path: "/app/loanapplications?activetab=approved&status=APPROVED",
    },
    {
      title: "Agreement",
      count: applicationStatusData?.agreementApplications?.applicationCount,
      amount:
        applicationStatusData?.agreementApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFilePenIcon,
      path: "/app/loanapplications?activetab=approved&status=AGREEMENT",
    },
    {
      title: "Disbursed",
      count: applicationStatusData?.disbursedApplications?.applicationCount,
      amount:
        applicationStatusData?.disbursedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileRightIcon,
      path: "/app/loandetails?activetab=active",
    },
    {
      title: "Rejected",
      count: applicationStatusData?.rejectedApplications?.applicationCount,
      amount:
        applicationStatusData?.rejectedApplications?.applicationLoanAmount,
      icon: imagepaths.dashboardFileCrossIcon,
      path: "/app/loanapplications?activetab=rejected",
    },
  ];

  // OVERALL PORTFOLIO
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getActivitiesOverdueYesterday = () => {
    props.apis.apiGetActivitiesOverdueYesterday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesOverdueYesterday: res,
      }));
    });
  };

  const getActivitiesCollectionsYesterday = () => {
    props.apis.apiGetActivitiesCollectionsYesterday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesCollectionsYesterday: res,
      }));
    });
  };

  const getActivitiesOverdueToday = () => {
    props.apis.apiGetActivitiesOverdueToday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesOverdueToday: res,
      }));
    });
  };

  const getActivitiesCollectionsToday = () => {
    props.apis.apiGetActivitiesCollectionsToday().then((res) => {
      console.log({ res });
      setActivitiesData((prevState) => ({
        ...prevState,
        activitiesCollectionsToday: res,
      }));
    });
  };

  // OVERDUE PORTFOLIO
  const GetDashboardAdminOverduePortfolio = () => {
    props.apis.apiGetDashboardAdminOverduePortfolio().then((res) => {
      console.log({ res });
      setOverallPortfolioData(res);
    });
  };

  // APPLICATION STATUS
  const getTotalApplicationStatus = () => {
    props.apis.apiGetTotalApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        totalApplications: res,
      }));
    });
  };

  const getPendingApplicationStatus = () => {
    props.apis.apiGetPendingApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        pendingApplications: res,
      }));
    });
  };

  const getUnderwritingApplicationStatus = () => {
    props.apis.apiGetUnderwritingApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        underwritingApplications: res,
      }));
    });
  };

  const getSanctionedApplicationStatus = () => {
    props.apis.apiGetSanctionedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        sanctionedApplications: res,
      }));
    });
  };

  const getApprovedApplicationStatus = () => {
    props.apis.apiGetApprovedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        approvedApplications: res,
      }));
    });
  };

  const getAgreementApplicationStatus = () => {
    props.apis.apiGetAgreementApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        agreementApplications: res,
      }));
    });
  };

  const getDisbursedApplicationStatus = () => {
    props.apis.apiGetDisbursedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        disbursedApplications: res,
      }));
    });
  };

  const getRejectedApplicationStatus = () => {
    props.apis.apiGetRejectedApplicationStatus().then((res) => {
      setApplicationStatusData((prevState) => ({
        ...prevState,
        rejectedApplications: res,
      }));
    });
  };

  useEffect(() => {
    // ACTIVITIES
    getActivitiesOverdueYesterday();
    getActivitiesCollectionsYesterday();
    getActivitiesOverdueToday();
    getActivitiesCollectionsToday();

    // OVERDUE PORTFOLIO
    GetDashboardAdminOverduePortfolio();

    // APPLICATION STATUS
    getTotalApplicationStatus();
    getPendingApplicationStatus();
    getUnderwritingApplicationStatus();
    getSanctionedApplicationStatus();
    getApprovedApplicationStatus();
    getAgreementApplicationStatus();
    getDisbursedApplicationStatus();
    getRejectedApplicationStatus();
  }, []);

  props.$setNode({
    props,
    overallPortfolioData,
    activitiesData,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Dashboard"
        pageTitle="Dashboard"
        parentTitle="Home"
        pageIcon={imagepaths.DashboardActive}
      />
      <div className="container-fluid">
        <h2 className="fs-4 mb-3">Overall Portfolio</h2>
        <OverallPortfolioCard />

        <div className="filter_tab_container">
          {filterTabData?.map((btn) => {
            return (
              <>
                <button
                  type="button"
                  id={btn.key}
                  className={Object.className({
                    active: activeKey === btn.key,
                  })}
                  onClick={(...args) => handleSelect(...args, btn.key)}
                >
                  {btn.name}
                </button>
              </>
            );
          })}
        </div>
        {activeKey === "portfolio_info" && (
          <>
            <h2 className="fs-4 mt-4 mb-3">Revenue Info.</h2>
            <RevenueInfo />
            <h2 className="fs-4 mt-4 mb-5">Activities</h2>
            <div className="row g-5 g-lg-4">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="card activity_card">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 position-relative">
                      <div className="dashboard_card application_card left">
                        <div className="card-body">
                          <div className="icon_container">
                            <div className="icon_label">
                              <p className="m-0">Yesterday</p>
                            </div>
                            <div className="icon">
                              <img
                                src={imagepaths.dollarBagInHandIcon}
                                alt="icon image"
                              />
                            </div>
                          </div>
                          <div className="content">
                            <p className="icon_title">Due Yesterday</p>
                            <p className="count">
                              {
                                activitiesData?.activitiesOverdueYesterday
                                  ?.overdueCount
                              }
                            </p>
                            <p className="card_label">
                              Amount :
                              <span className="primary-red ms-1">
                                {activitiesData?.activitiesOverdueYesterday?.overdueAmt?.cur()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="verticle_line d-none d-md-block"></div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="dashboard_card application_card right">
                        <div className="card-body">
                          <div className="icon_container">
                            <div className="icon">
                              <img
                                src={imagepaths.dollarCollectionIcon}
                                alt="icon image"
                              />
                            </div>
                          </div>
                          <div className="content">
                            <p className="icon_title">Collections</p>
                            <p className="count">
                              {
                                activitiesData?.activitiesCollectionsYesterday
                                  ?.repaymentCount
                              }
                            </p>
                            <p className="card_label">
                              Amount :
                              <span className="primary-red ms-1">
                                {activitiesData?.activitiesCollectionsYesterday?.repaymentAmt?.cur()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border_bottom_line"></div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="card activity_card">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 position-relative">
                      <div className="dashboard_card application_card left">
                        <div className="card-body">
                          <div className="icon_container">
                            <div className="icon_label">
                              <p className="m-0">Today</p>
                            </div>
                            <div className="icon">
                              <img
                                src={imagepaths.dollarBagInHandIcon}
                                alt="icon image"
                              />
                            </div>
                          </div>
                          <div className="content">
                            <p className="icon_title">Due Today</p>
                            <p className="count">
                              {
                                activitiesData?.activitiesOverdueToday
                                  ?.overdueCount
                              }
                            </p>
                            <p className="card_label">
                              Amount :
                              <span className="primary-red ms-1">
                                {activitiesData?.activitiesOverdueToday?.overdueAmt?.cur()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="verticle_line d-none d-md-block"></div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="dashboard_card application_card right">
                        <div className="card-body">
                          <div className="icon_container">
                            <div className="icon">
                              <img
                                src={imagepaths.dollarCollectionIcon}
                                alt="icon image"
                              />
                            </div>
                          </div>
                          <div className="content">
                            <p className="icon_title">Collections</p>
                            <p className="count">
                              {
                                activitiesData?.activitiesCollectionsToday
                                  ?.repaymentCount
                              }
                            </p>
                            <p className="card_label">
                              Amount :
                              <span className="primary-red ms-1">
                                {activitiesData?.activitiesCollectionsToday?.repaymentAmt?.cur()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border_bottom_line"></div>
                </div>
              </div>
            </div>

            <h2 className="fs-4 mt-4 mb-3">Over Due Portfolio</h2>
            <div className="row">
              {overdueCardData &&
                overdueCardData.map((data) => {
                  return (
                    <div className="col-xl-3 col-sm-6">
                      <div className="card dashboard_card overdue_card">
                        <div className="border_bottom_line"></div>
                        <div className="card-body">
                          <div className="icon">
                            <p className="count_text">{data.title}</p>
                            <p className="count_label">Days</p>
                          </div>
                          <div className="content">
                            <p className="count">{data.count}</p>
                            <p className="card_label">
                              Amount :
                              <span className="primary-red ms-1">
                                {data?.amount?.cur()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {activeKey === "sales_info" && (
          <>
            <h2 className="fs-4 mt-4">Disbursement Details</h2>
            <DisbursementDetailsInfo />

            <h2 className="fs-4 mt-4 mb-3">User Registration</h2>
            <UserRegistrationInfo />

            <h2 className="fs-4 mt-4 mb-3">Lead Info</h2>
            <LeadInfo />

            <h2 className="fs-4 mt-4 mb-3">Tele callers Info.</h2>
            <TelecallerInfoCard title="Lead Status" />
          </>
        )}
        {activeKey === "credit_info" && (
          <>
            <h2 className="fs-4 mt-4 mb-3">Application Status</h2>
            <div className="row">
              {applicationStatusCardData &&
                applicationStatusCardData.map((application) => {
                  return (
                    <>
                      <div
                        className="col-xl-3 col-sm-6"
                        onClick={(...args) => navigate(application?.path)}
                      >
                        <div className="card dashboard_card application_card">
                          <div className="border_bottom_line"></div>
                          <div className="card-body">
                            <div className="icon_container">
                              <p className="icon_title">{application?.title}</p>
                              <div className="icon">
                                <img src={application?.icon} alt="icon image" />
                              </div>
                            </div>
                            <div className="content">
                              <p className="count">{application?.count}</p>
                              <p className="card_label">
                                Amount :{" "}
                                <span className="primary-red ms-1">
                                  {application?.amount?.cur()}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default constore(Home);
