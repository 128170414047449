import React, { useEffect, useState } from "react";
import DocsCard from "../../MyProfile/DocsCard";
import FlexCard from "../Helpers/FlexCard";
import { Link } from "react-router-dom";
import CommunicationModal from "./CommunicationModal";
import moment from "moment";

const PersonalInfo = (props) => {
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const frompath = props?.urlparams?.params?.frompath;
  const [empdata, setEmpdata] = useState({});
  const [myinfo, setMyinfo] = useState({});
  const [maindata, setMaindata] = useState({});
  const [proofdata, setProofdata] = useState({});
  const [applicationAddressInfo, setApplicationAddressInfo] = useState({});
  const [customerAddressData, setCustomerAddressData] = useState({});
  // ----------------------------------------------------------------

  const [modalFields, setModalFields] = useState([
    {
      label: "Residence Status",
      name: "residenceStatus",
      type: "select",
      options: _enum.RESIDENCE_TYPES,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "docType",
      label: "Document Proof",
      type: "select",
      options: _enum.DOC_TYPES_NEW,
      // readOnly: true,
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "address1",
      label: "Address 1",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "address2",
      label: "Address 2",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "address3",
      label: "Address 3",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [queryinfo, setQueryinfo] = useState([]);

  const openModal = () => {
    setOpenCanvas(true);
    addModalFields();
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setErrorMessage({ name: "", message: "" });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const editCommunicationAddress = (e, record) => {
    setEditmode(true);
    setViewmode(false);

    let filteredData = Object.only(
      record,
      modalFields.map((v) => v.name)
    );
    console.log({ record, filteredData });

    setPopupdata(filteredData);
    setEditData(record);
    let updatedFields = modalFields?.map((field) => {
      return {
        ...field,
        defaultValue: filteredData[field?.name],
      };
    });
    setModalFields([...updatedFields]);
    openModal();
  };

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let payload = {
      ...data,
    };
    console.log({ payload, editData });

    props.apis
      .apiUpdateUnderWriterApplicationAddress(payload, {
        addressId: editData?.addressId,
      })
      .then((res) => {
        console.log({ apiUpdateEmployee: res });
        swal2.fire({
          title: "Communication address updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeModal();
        getUnderwriterInfo();
      })
      .catch((error) => console.log(error));
  };
  // ----------------------------------------------------------------

  const getQueries = () => {
    props.apis
      .apiUnderWriterGetApplicationQuery({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        let obj = Object.groupBy(res, (item) => {
          return item.category;
        });

        setQueryinfo(obj["PERSONAL_INFO"] || []);
        // prepareSummaryInfo(res);
      });
  };

  // ----------------------------------------------------------------

  const getUnderwriterInfo = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        if (res) {
          console.log({ res });
          props.onGetName(res.customerName);
          setEmpdata(res);
          setMyinfo({
            panFileMetaData: {
              fileId: res?.customer?.panFileId,
            },
            // aadharFrontFileMetaData: {
            //   fileId: res?.customer?.aadharFrontFileId,
            // },
            // aadharBackFileMetaData: {
            //   fileId: res?.customer?.aadharBackFileId,
            // },
            photoFileMetaData: {
              fileId: res?.customer?.photoFileId,
            },
          });
          setMaindata({
            user_id: res?.customerId,
            gender: res?.customer?.gender,
            dob: moment(res?.customer?.dob)?.format("DD-MM-YYYY"),
            age: moment().diff(res?.customer?.dob, "years"),
            mobileNumber: res?.customer?.mobileNumber,
            emailAddress: res?.customer?.emailAddress,
            pincode: res?.customer?.customerAadharCardInfo?.pincode,
            aadhar_no: `********${res?.customer?.customerAadharCardInfo?.uid}`,
            pan_number: res?.customer?.customerPanCardInfo?.number,
            aadhar_address: res?.customer?.customerAadharCardInfo?.address,
          });
          setProofdata({
            name_as_per_application: res.customerName,
            name_as_per_aadhar_card:
              res?.customer?.customerAadharCardInfo?.name,
            name_as_per_pan_card: res?.customer?.customerPanCardInfo?.name,
            aadhar_seeding_successful:
              res?.customer?.customerPanCardInfo?.aadhaarSeedingStatus,
          });
          let addr1 = res?.ApplicationAddressInfo[0]?.address1?.toTitleCase();
          let addr2 = res?.ApplicationAddressInfo[0]?.address2?.toTitleCase();
          let addr3 = res?.ApplicationAddressInfo[0]?.address3?.toTitleCase();

          let address = {
            ...Object.except(res?.ApplicationAddressInfo[0], [
              "fileName",
              "addressId",
              "address1",
              "address2",
              "address3",
            ]),
            address: `${addr1 ? addr1 + "," : ""} ${addr2 ? addr2 + "," : ""} ${
              addr3 ? addr3 + "." : ""
            }`,
          };
          address.docType = _enum.DOC_TYPES_MAP[address?.docType];
          setCustomerAddressData(address);
          setApplicationAddressInfo(res?.ApplicationAddressInfo[0]);
        }
      });
  };
  useEffect(() => {
    getUnderwriterInfo();
    getQueries();
  }, []);

  const handleDecision = (e, data) => {
    let parsedPayload = parseInput(data);
    // console.log("formData", data, parsedPayload);
    let payload = {
      ...parsedPayload,
      applicationId: parseInt(props.urlparams.params.id),
      category: "PERSONAL_INFO",
    };
    console.log({ payload });
    props.apis
      .apiUnderWriterCreateApplicationQuery(payload)
      .then((res) => {
        swal2.fire({
          title: "Underwriter decision updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        props.changeTab(e, "incomedetails");
      })
      .catch((error) => {});
  };

  const getBankLink = () => {
    let link;
    if (frompath === "loanapplications") {
      link = `/app/loanapplications?activetab=${tabStatus}`;
    }
    if (frompath === "dupecheck") {
      link = `/app/dupecheck`;
    }
    return link;
  };

  props.$setNode({
    props,
    maindata,
    customerAddressData,
  });
  return (
    <>
      <div className="card employee_card">
        <div className="card-body">
          <div className="card_header">
            <div className="avatar_card">
              <div className="me-3">
                <img
                  src={imagepaths.fakeUserIcon}
                  className="avatar"
                  alt="avatar"
                />
              </div>
              <div className="content">
                <h3 className="title">{empdata?.customerName || "-"}</h3>
              </div>
            </div>
          </div>
          <hr />
          <div className="card_body row mx-auto">
            <FlexCard
              data={maindata}
              verifiedIconsFor={["mobile_no", "email_id"]}
            />
          </div>
        </div>
      </div>
      <div className="card p-4">
        <FlexCard data={proofdata} />
      </div>
      <div className="card p-4">
        <FlexCard
          title="Communication Address"
          data={customerAddressData}
          hasFileId={customerAddressData?.docType !== undefined ? true : false}
          fileIdKey="docType"
          editable={
            isReadonly ? false : tabStatus !== "inprogress" ? false : true
          }
          onEdit={(...args) =>
            editCommunicationAddress(...args, applicationAddressInfo)
          }
        />
      </div>
      <DocsCard data={myinfo} />
      {isReadonly ? (
        <div className="nav_container mt-5">
          <Link to={getBankLink()} className="btn btn-primary-lite me-3">
            Cancel
          </Link>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => props.changeTab(...args, "incomedetails")}
          >
            Next
          </button>
        </div>
      ) : !isReadonly && tabStatus === "sanctioned" ? (
        <>
          <div className="nav_container mt-5">
            <Link to={getBankLink()} className="btn btn-primary-lite me-3">
              Cancel
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(...args) => props.changeTab(...args, "incomedetails")}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <div
          className={Object.className({
            "d-none": tabStatus === "sanctioned",
          })}
        >
          {queryinfo
            .filter((q) => !q.responseId)
            .map((item) => (
              <>
                <div className="row">
                  <AppInput
                    name="uwDecision"
                    label="Underwriter Decision"
                    type="text"
                    defaultValue={item.uwDecision}
                    outerclass="col-12 col-md-3"
                    readOnly="true"
                  ></AppInput>

                  <AppInput
                    name="messageText"
                    label="Remarks"
                    readOnly="true"
                    defaultValue={item.messageText}
                    type="textarea"
                    outerclass="col-12 col-md-9 mb-3"
                  ></AppInput>
                </div>
              </>
            ))}
          <AppForm
            className="mx-auto"
            onSubmit={(...args) => handleDecision(...args)}
          >
            <AppInput
              name="uwDecision"
              label="Underwriter Decision"
              type="select"
              options={_enum.UNDER_WRITER_DECISIONS}
              outerclass="col-12 col-md-3"
              required="true"
            ></AppInput>

            <AppInput
              name="messageText"
              label="Remarks"
              type="textarea"
              outerclass="col-12 col-md-9 mb-3"
            ></AppInput>
            <div className="nav_container mt-5">
              <Link
                to={`/app/loanapplications?activetab=${tabStatus}`}
                className="btn btn-primary-lite me-3"
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>
        </div>
      )}
      <CommunicationModal
        id="employeeCanvas"
        title={`${
          editmode
            ? "Edit Communication Address"
            : viewmode
            ? "View Communication Address"
            : "Add Communication Address"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default constore(PersonalInfo);
