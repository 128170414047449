import React, { useEffect, useState } from "react";
import TransactionSearch from "./TransactionSearch";
import { Dropdown } from "react-bootstrap";

const TransactionTable = (props) => {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [lastSixMonthDate, setLastSixMonthDate] = useState(null);

  const columns = [
    {
      dataIndex: "transferType",
      title: "Transaction Type",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    {
      dataIndex: "amount",
      title: "Transaction Amt.",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Date & Time",
      render: (text, record) => {
        let dt = new Date(text);
        let date = new Date(dt).dateToDDMMYYYY(dt);
        let time = dt.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        return <span>{`${date} ${time}`}</span>;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let status = record?.status || "pending";
        let mappedStatus = _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

        return (
          <Dropdown className="status-dropdown pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              {status?.toTitleCase()}
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];

  const getTransactions = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
      console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis
      .apiAdminGetAllPayoutTransactions(payload)
      .then(({ data, total }) => {
        console.log({ apiGetConnectorPayouts: data });
        setData(data);
        setRowcount(total);
        setIsFetching(false);
      });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch", data, reset);
    let searchdata = {
      startDate: new Date(data?.startDate).toISOString(),
      endDate: new Date(data?.endDate).toISOString(),
    };
    if (Object.keys(data).length === 0) {
      setSearch({
        ...searchdata,
        ...search,
      });
      getTransactions(searchdata, reset);
    } else {
      setSearch({
        ...searchdata,
        ...search,
      });
      getTransactions(searchdata, reset);
    }
  };

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getSixMonthsAgoDate = () => {
    const currentDate = new Date();
    const sixMonthsAgoDate = new Date(currentDate);
    sixMonthsAgoDate.setMonth(currentDate.getMonth() - 6);
    return sixMonthsAgoDate.toISOString();
  };

  const getThreeMonthsAgoDate = () => {
    const currentDate = new Date();
    const threeMonthsAgoDate = new Date(currentDate);
    threeMonthsAgoDate.setMonth(currentDate.getMonth() - 3);
    return threeMonthsAgoDate.toISOString();
  };

  useEffect(() => {
    let searchValue = props?.keyvalue;
    let payload = {};

    // DATE CALCULATIONS
    // start date
    var midnightDate = new Date();
    midnightDate.setHours(0, 0, 0, 0);
    var startDate = midnightDate.toISOString();

    // end date
    var elevenFiftyNinePMDate = new Date();
    elevenFiftyNinePMDate.setHours(23, 59, 0, 0);
    var endDate = elevenFiftyNinePMDate.toISOString();

    if (searchValue === "this-month") {
      let today = new Date();
      let startOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      ).toISOString();
      payload.startDate = startOfMonth;
      payload.endDate = today.toISOString();
    } else if (searchValue === "last-three-months") {
      let sd = startDate;
      payload.startDate = getThreeMonthsAgoDate();
      payload.endDate = sd;
    } else if (searchValue === "last-six-months") {
      let sd = startDate;
      payload.startDate = getSixMonthsAgoDate();
      payload.endDate = sd;
    } else {
      payload.startDate = startDate;
      payload.endDate = endDate;
    }
    console.log(props?.keyvalue, payload);
    let defaultSearchData = Object.only(search, ["page", "limit"]);
    getTransactions();
  }, []);

  return (
    <>
      {props?.keyvalue === "custom" && (
        <TransactionSearch onSearch={(...arg) => onSearch(...arg)} />
      )}
      <AppTable
        data={data}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editable={false}
        viewable={false}
        deletable={false}
        loading={isFetching}
      ></AppTable>
    </>
  );
};

export default constore(TransactionTable);
