import React, { useEffect, useRef, useState } from "react";

const UserRegistrationInfo = (props) => {
  const infoForm = useRef(null);
  const [userRegistrationData, setUserRegistrationData] = useState({});
  const [timeline, setTimeline] = useState("TODAY");

  const userRegistrationCardData = [
    {
      id: "totalusers",
      title: "Total Users",
      count: userRegistrationData?.totalusers || 0,
      icon: imagepaths.AdminLeadsIconActive,
      path: "/app/registeredUsers",
    },
    {
      id: "pendingusers",
      title: "Profile Pending",
      count: userRegistrationData?.pendingusers || 0,
      icon: imagepaths.userWarningIcon,
      path: `/app/registeredUsers?status=${_enum.CUSTOMER_PROFILE_STATUS.PENDING}`,
    },
    {
      id: "completedusers",
      title: "Profile Completed",
      count: userRegistrationData?.completedusers || 0,
      icon: imagepaths.userCheckmarkIcon,
      path: `/app/registeredUsers?status=${_enum.CUSTOMER_PROFILE_STATUS.COMPLETED}`,
    },
    {
      id: "appliedusers",
      title: "Applied",
      count: userRegistrationData?.appliedusers || 0,
      icon: imagepaths.userRightIcon,
      path: `/app/registeredUsers?status=${_enum.CUSTOMER_PROFILE_STATUS.APPLIED}`,
    },
  ];
  // USER REGISTRATIONS
  const getTotalRegisteredUsers = (query, params) => {
    let data = {
      ...(params && params === "CUSTOM"
        ? {
            ...query,
            timeline: "CUSTOM",
          }
        : {
            ...query,
            timeline: timeline,
          }),
    };
    // console.log({ params, data, timeline });

    props.apis.apiGetTotalRegisteredUsers(data).then((res) => {
      setUserRegistrationData((prevState) => ({
        ...prevState,
        totalusers: res?.totalCustomerCount,
      }));
    });
  };

  const getPendingRegisteredUsers = (query, params) => {
    let data = {
      ...(params && params === "CUSTOM"
        ? {
            ...query,
            timeline: "CUSTOM",
          }
        : {
            ...query,
            timeline: timeline,
          }),
    };

    props.apis.apiGetPendingRegisteredUsers(data).then((res) => {
      setUserRegistrationData((prevState) => ({
        ...prevState,
        pendingusers: res?.totalPendingCustomerCount,
      }));
    });
  };

  const getCompletedRegisteredUsers = (query, params) => {
    let data = {
      ...(params && params === "CUSTOM"
        ? {
            ...query,
            timeline: "CUSTOM",
          }
        : {
            ...query,
            timeline: timeline,
          }),
    };

    props.apis.apiGetCompletedRegisteredUsers(data).then((res) => {
      setUserRegistrationData((prevState) => ({
        ...prevState,
        completedusers: res?.totalCompletedCustomerCount,
      }));
    });
  };

  const getAppliedRegisteredUsers = (query, params) => {
    let data = {
      ...(params && params === "CUSTOM"
        ? {
            ...query,
            timeline: "CUSTOM",
          }
        : {
            ...query,
            timeline: timeline,
          }),
    };

    props.apis.apiGetAppliedRegisteredUsers(data).then((res) => {
      setUserRegistrationData((prevState) => ({
        ...prevState,
        appliedusers: res?.totalAppliedCustomerCount,
      }));
    });
  };

  const showInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
        }
      });
    }
  };

  const hideInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
        }
      });
    }
  };

  const handleDateChange = (value) => {
    let val = value[0]?.key || "TODAY";
    let inputs = [
      "registration-column-startDate",
      "registration-column-endDate",
      "registration-column-search_btn",
      "registration-column-reset_btn",
    ];
    console.log({ val });
    if (val === "CUSTOM") {
      showInputs(inputs);
      setUserRegistrationData((prevState) => ({
        ...prevState,
        totalusers: "",
        pendingusers: "",
        completedusers: "",
        appliedusers: "",
      }));
    } else {
      setTimeline(val);
      hideInputs(inputs);
    }
  };

  const onSearch = () => {
    let data = infoForm.current.form.current.getData();
    let queryData = {
      ...Object.except(data, ["timeline"]),
    };
    getTotalRegisteredUsers(queryData, "CUSTOM");
    getPendingRegisteredUsers(queryData, "CUSTOM");
    getCompletedRegisteredUsers(queryData, "CUSTOM");
    getAppliedRegisteredUsers(queryData, "CUSTOM");
  };

  const resetSearchForm = () => {
    infoForm?.current?.form?.current?.reset();
    let inputs = [
      "registration-column-startDate",
      "registration-column-endDate",
      "registration-column-search_btn",
      "registration-column-reset_btn",
    ];
    hideInputs(inputs);
    getTotalRegisteredUsers({}, "TODAY");
    getPendingRegisteredUsers({}, "TODAY");
    getCompletedRegisteredUsers({}, "TODAY");
    getAppliedRegisteredUsers({}, "TODAY");
  };

  useEffect(() => {
    // USER REGISTRATIONS
    getTotalRegisteredUsers();
    getPendingRegisteredUsers();
    getCompletedRegisteredUsers();
    getAppliedRegisteredUsers();
  }, [timeline]);

  props.$setNode({
    props,
    userRegistrationData,
  });
  return (
    <>
      <div className="mb-4 dashboard_form_box">
        <div className="form_outer">
          <AppForm
            ref={infoForm}
            onSubmit={(...args) => onSearch(...args)}
            className="row justify-content-end"
          >
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2"
              id="column-timeline"
            >
              <AppInputFocus
                type="select"
                name="timeline"
                label="Time Line"
                options={_enum.OVERALL_DETAIL}
                onChange={(...args) => handleDateChange(...args)}
                defaultValue={"TODAY"}
                placeholder="Time Line"
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="registration-column-startDate"
            >
              <AppInputFocus
                type="date"
                name="startDate"
                label="Start Date"
                placeholder="Start Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="registration-column-endDate"
            >
              <AppInputFocus
                type="date"
                name="endDate"
                label="End Date"
                placeholder="End Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="registration-column-search_btn"
            >
              <button type="submit" className="btn btn-primary search_btn">
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="registration-column-reset_btn"
            >
              <button
                type="button"
                className="btn btn-primary search_btn"
                onClick={(...args) => resetSearchForm(...args)}
              >
                <i class="fa-solid fa-filter-circle-xmark"></i>
                <span>Search</span>
              </button>
            </div>
          </AppForm>
        </div>
        <div className="row pt-0 pt-md-4">
          {userRegistrationCardData &&
            userRegistrationCardData.map((data) => {
              return (
                <>
                  <div
                    className="col-xl-3 col-sm-6"
                    onClick={(...args) => navigate(data?.path)}
                  >
                    <div className="card dashboard_card">
                      <div className="border_bottom_line"></div>
                      <div className="card-body">
                        <div className="icon">
                          <img src={data?.icon} alt="AdminLeadsIconActive" />
                        </div>
                        <div className="content">
                          <p className="count">{data?.count}</p>
                          <p className="card_label">{data?.title}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default constore(UserRegistrationInfo);
