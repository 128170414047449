export default {
  // APPLICATIONS
  ADD_APPLICATION: {
    url: "/api/v1/applications",
    method: "post",
  },
  GET_APPLICATIONS: {
    url: "/api/v1/customer/applications",
    method: "get",
  },
  GET_APPLICATION_BY_ID: {
    url: "/api/v1/applications/{applicationNumber}",
    method: "get",
  },
  UPDATE_APPLICATION: {
    url: "/api/v1/applications/{applicationNumber}",
    method: "put",
  },
  DELETE_APPLICATION: {
    url: "/api/v1/applications/{applicationNumber}",
    method: "delete",
  },
  REOPEN_APPLICATION: {
    url: "/api/v1/admin/application/reopen/{applicationNumber}",
    method: "put",
  },
  SOFT_DELETE_APPLICATION: {
    url: "/api/v1/admin/application/{applicationId}",
    method: "delete",
  },
  PAYIN: {
    url: "/api/v1/loan-pg/payin/web/request",
    method: "post",
  },
  SEND_LOAN_AMOUNT_TO_CUSTOMER: {
    url: "/api/v1/loan-pg/payout/disburse/{loanAccountNumber}",
    method: "post",
  },
  ADMIN_FETCH_TRANSACTION_STATUS: {
    url: "/api/v1/loan-pg/payout/status/{refereceId}",
    method: "get",
  },
  ADMIN_MANUALLY_ADD_CUSTOMER_PAYOUT_DETAILS: {
    url: "/api/v1/loan-pg/payout/manual/{loanAccountNumber}",
    method: "post",
  },
  GET_CUSTOMER_PAYIN_TRANSACTIONS: {
    url: "/api/v1/loan-pg/payin/customer/transaction",
    method: "get",
  },
  ADMIN_GET_CURRENT_WALLET_BALANCE: {
    url: "/api/v1/loan-pg/payout/balance",
    method: "get",
  },
  ADMIN_GET_ALL_PAYOUT_TRANSACTIONS: {
    url: "/api/v1/loan-pg/payout/transactions",
    method: "get",
  },
};
