import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import Cibil from "./Cibil/Cibil";
import FinalReview from "./FinalReview/FinalReview";
import IncomeDetails from "./IncomeDetails/IncomeDetails";
import Loans from "./Loans/Loans";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import PostSanctionedDocs from "./PostSanctionedDocs/PostSanctionedDocs";
import Queries from "./Queries/Queries";

const LoanDetailsActiveView = (props) => {
  const [activeKey, setActiveKey] = useState("loans");
  const tabData = [
    { key: "loans", name: "Loans & Repayments" },
    { key: "personalinfo", name: "Personal Info" },
    { key: "incomedetails", name: "Income & BSA Info." },
    { key: "cibil", name: "CIBIL" },
    { key: "queries", name: "Queries" },
    { key: "finalreview", name: "Final Review" },
    { key: "postsanctioneddocs", name: "Post Sanctioned Documents" },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const changeTab = (e, key) => {
    console.log({ key });
    setActiveKey(key);
    scrollToTop();
  };

  const getMainTitle = () => {
    let mainTitle = "Loan details | Active Loans";
    return mainTitle;
  };

  const getBreadCums = () => {
    let pagetitle = props?.urlparams?.params?.name || "";
    return pagetitle;
  };

  return (
    <div>
      <MainPagetitle
        mainTitle={getMainTitle()}
        back={getBreadCums()}
        backLink={`/app/loandetails?activetab=active`}
        pageTitle={"Loan details"}
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckImg}
      />
      {/* <!-- Nav tabs --> */}
      <div className="custom-tab-1 p-4">
        <Tab.Container
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          <Nav as="ul" className="nav-tabs border-0">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link
                  eventKey={data.key}
                  className={Object.className({
                    "text-dark bg-transparent": true,
                    "pe-none": data.disabled === true ? true : false,
                  })}
                >
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="pt-4">
            {activeKey === "loans" && (
              <Tab.Pane eventKey="loans">
                <Loans changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "personalinfo" && (
              <Tab.Pane eventKey="personalinfo">
                <PersonalInfo changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "incomedetails" && (
              <Tab.Pane eventKey="incomedetails">
                <IncomeDetails changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "cibil" && (
              <Tab.Pane eventKey="cibil">
                <Cibil changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "queries" && (
              <Tab.Pane eventKey="queries">
                <Queries changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "finalreview" && (
              <Tab.Pane eventKey="finalreview">
                <FinalReview changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "postsanctioneddocs" && (
              <Tab.Pane eventKey="postsanctioneddocs">
                <PostSanctionedDocs
                  changeTab={(...args) => changeTab(...args)}
                  handlePostSanction={(...args) => handlePostSanction(...args)}
                />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default constore(LoanDetailsActiveView);
